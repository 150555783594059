<div class="dashboard-timezone">
  <mat-form-field appearance="outline" floatLabel="always" id="vi_timezone_dropdown_dashboard">
    <mat-select
      [(ngModel)]="currentZoneDetails"
      (selectionChange)="onTimezoneChange()"
      class="timezone-field"
      hideSingleSelectionIndicator="true">
      <mat-option *ngFor="let zone of timezoneList" [value]="zone">
        {{ zone.label + ' ' + zone.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="all-chart-container" *ngIf="!summaryLoader && !riskLoader; else chartLoader">
  <div #graphChartContainer class="graph-chart-container">
    <mat-card class="no-link">
      <div class="main-chart" id="graph_chart">
        <div class="graph-content">
          <mat-card-header class="graph-header">
            <mat-card-title class="graph-title"
              ><span class="total-event-title">Total events: {{ totalEvents }}</span>
              <mat-card-subtitle class="sub-label">{{ chartTimeLine }}</mat-card-subtitle>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="line-chart-container">
            <div class="event-chart" *ngIf="totalEvents; else no_data">
              <div
                *ngIf="isFullScreen"
                class="custom-highcharts custom-highcharts-container custom-highcharts-legend-box custom-highcharts-line-series full-screen">
                <highcharts-chart
                  [Highcharts]="Highcharts"
                  [options]="eventLineChartOption"
                  [(update)]="updateEventLineChart"
                  class="custom-highcharts-container"></highcharts-chart>
              </div>
              <div *ngIf="!isFullScreen">
                <highcharts-chart
                  [Highcharts]="Highcharts"
                  [options]="eventLineChartOption"
                  [(update)]="updateEventLineChart"></highcharts-chart>
              </div>
            </div>
          </mat-card-content>
        </div>
        <div class="event-status-line-container">
          <div [ngClass]="[isFullScreen ? 'full-screen-vertical-line' : 'vertical-line']" *ngIf="totalEvents"></div>
          <div [ngClass]="[isFullScreen ? 'full-screen-event-status-container' : 'event-status-container']" *ngIf="totalEvents">
            <div class="event-status-row" *ngIf="validCount !== undefined && validCount !== null">
              <mat-icon svgIcon="check" class="status-icon valid-icon"></mat-icon>
              <div class="event-status-count">{{ validCount }}<span class="event-status-text">Valid</span></div>
            </div>
            <div class="event-status-row" *ngIf="invalidCount !== undefined && invalidCount !== null">
              <mat-icon svgIcon="check" class="status-icon invalid-icon"></mat-icon>
              <div class="event-status-count">{{ invalidCount }}<span class="event-status-text">Invalid</span></div>
            </div>
            <div class="event-status-row" *ngIf="notReviewedCount !== undefined && notReviewedCount !== null">
              <mat-icon svgIcon="close" class="status-icon not-reviewed-icon"></mat-icon>
              <div class="event-status-count">{{ notReviewedCount }}<span class="event-status-text">Not reviewed</span></div>
            </div>
            <div class="event-status-row" *ngIf="excludeCount !== undefined && excludeCount !== null && isAdmin">
              <mat-icon svgIcon="remove" class="status-icon exclude-icon"></mat-icon>
              <div class="event-status-count">{{ excludeCount }}<span class="event-status-text">Excluded</span></div>
            </div>
          </div>
        </div>
      </div>
      <mat-icon svgIcon="close" *ngIf="isFullScreen" class="close-icon" (click)="exitFullScreen()"></mat-icon>
      <div class="side-icon" *ngIf="totalEvents">
        <mat-icon
          svgIcon="hamburger"
          *ngIf="!isFullScreen"
          class="icon hamburger-icon"
          [class.active]="isMenuOpen"
          (click)="toggleMenu()"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"></mat-icon>
        <mat-menu class="download-list" #menu="matMenu" (closed)="onMenuClose()">
          <div class="download-options" mat-menu-item (click)="downloadAsPngJpeg('png'); closeMenu()">
            <span class="download-menu">Download PNG image</span>
          </div>
          <div class="download-options" mat-menu-item (click)="downloadAsPngJpeg('jpeg'); closeMenu()">
            <span class="download-menu">Download JPEG image</span>
          </div>
          <div class="download-options" mat-menu-item (click)="downloadAsPdf(); closeMenu()">
            <span class="download-menu">Download PDF document</span>
          </div>
        </mat-menu>
        <mat-icon svgIcon="expand-1" *ngIf="!isFullScreen" class="icon fullscreen-icon" (click)="toggleFullScreen()"></mat-icon>
      </div>
      <ng-template #no_data>
        <p class="align-center" id="vi_dashboard_gateways_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
  </div>
  <div class="risk-chart-container" *ngIf="totalEvents">
    <mat-card class="no-link" id="vi_total_violation_chart_dashboard">
      <mat-card-header class="event-header">
        <mat-card-title class="risk-event-header" id="vi_total_violation_chart_dashboard_header">At risk events</mat-card-title>
        <mat-card-subtitle class="sub-label risk-chart">{{ chartTimeLine }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="chart-container">
        <div class="risk-status-button-group">
          <div
            *ngIf="enableRedzoneRiskStatus"
            class="btn"
            [ngClass]="{ 'selected-btn': selectedWf === 'redzone' }"
            (click)="onRiskEventClick('redzone')">
            <span class="btn-text">Red zone - {{ totalRedZoneEvents }}</span>
          </div>
          <div
            *ngIf="enablePpeRiskStatus"
            class="btn"
            [ngClass]="{ 'selected-btn': selectedWf === 'ppe' }"
            (click)="onRiskEventClick('ppe')">
            <span class="btn-text">PPE - {{ totalPpeEvents }}</span>
          </div>
          <div
            *ngIf="enableCatwalkRiskStatus"
            class="btn"
            [ngClass]="{ 'selected-btn': selectedWf === 'catwalk' }"
            (click)="onRiskEventClick('catwalk')">
            <span class="btn-text">Catwalk - {{ totalCatwalkEvents }}</span>
          </div>
          <div
            *ngIf="enablePiperackRiskStatus"
            class="btn"
            [ngClass]="{ 'selected-btn': selectedWf === 'piperack' }"
            (click)="onRiskEventClick('piperack')">
            <span class="btn-text">Piperack - {{ totalPiperackEvents }}</span>
          </div>
        </div>
        <div class="risk-chart-tab-group">
          <div class="" *ngFor="let tabs of riskDataWfList">
            <div class="risk-chart-tab">
              <p class="tab-value">{{ tabs.value }}</p>
              <p class="tab-text">{{ truncateTabName(tabs.name) }}</p>
            </div>
          </div>
          <div class="risk-status-error-message" *ngIf="isRiskStatusEmpty">
            <mat-error>Please select risk event type corresponding to {{ setLabel(selectedWf) }}</mat-error>
          </div>
        </div>
      </mat-card-content>
      <ng-template #no_event_status>
        <p class="align-center" id="vi_dashboard_cameras_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
  </div>
</div>
<ng-template #chartLoader>
  <mat-card>
    <mat-card-content class="doughnut-chart-loader"> <app-spinner></app-spinner> </mat-card-content>
  </mat-card>
</ng-template>
