<div id="vi_camera_profile_alerts_grid_container" *ngIf="!isLoading; else loader">
  <mat-card class="alert-grid-card" *ngFor="let data of gridData; let i = index">
    <mat-card-header class="d-block" id="{{ 'vi_camera_profile_alerts_grid_card_' + i }}">
      <mat-card-title id="{{ 'vi_camera_profile_alerts_grid_card_title_' + i }}">
        <div class="icon-chips">
          <div class="card-chkbx">
            <mat-checkbox class="checkbox" [(ngModel)]="data.isChecked" (change)="checkBoxSelection()"></mat-checkbox>
          </div>
          <div class="card-chips">
            <span
              *ngIf="data.isVideo"
              [ngClass]="
                data.category === 'EXCLUDE' || data.category === 'INVALID' || data.category === 'VALID'
                  ? 'video-download'
                  : 'category-video-download'
              "
              class="videos top-icons"></span>
            <span>
              <div
                [ngClass]="{
                  'valid-chip': (data.thumbnailFileUrl || data.thumbnailFileUrl === '') && data.category === 'VALID',
                  'invalid-chip': (data.thumbnailFileUrl || data.thumbnailFileUrl === '') && data.category === 'INVALID',
                  'exclude-chip': (data.thumbnailFileUrl || data.thumbnailFileUrl === '') && data.category === 'EXCLUDE',
                  'not-reviewed-chip':
                    (data.thumbnailFileUrl || data.thumbnailFileUrl === '') &&
                    (data.category === '' || data.category === null || data.category === 'NONE'),
                  'image-available-valid-chip': !data.thumbnailFileUrl
                }"
                class="grid-view-chip">
                <span *ngIf="data.category === 'VALID' || data.category === 'INVALID'">
                  <mat-icon class="valid-icon" svgIcon="check">check</mat-icon>
                </span>
                <span *ngIf="data.category === 'EXCLUDE'">
                  <mat-icon class="exclude-remove-icon" svgIcon="remove">remove</mat-icon>
                </span>
                <span *ngIf="!data.category || data.category === 'NONE'">
                  <mat-icon class="no-review-icon" svgIcon="close">close</mat-icon>
                </span>
                <span class="data-category">{{
                  data.category === '' || data.category === null || data.category === 'NONE'
                    ? 'Not reviewed'
                    : data.category === 'EXCLUDE'
                    ? 'Excluded'
                    : (data.category | titlecase)
                }}</span>
              </div>
            </span>
          </div>
        </div>
        <p class="event-time" id="{{ 'vi_camera_profile_alerts_grid_card_timestamp_' + i }}">
          {{ data.timestamp | date : 'MMM d, y, h:mm:ss a' : currentZone }}
        </p>
      </mat-card-title>
    </mat-card-header>
    <img
      *ngIf="data.thumbnailFileUrl; else errorImage"
      mat-card-image
      #eventImage
      class="cursor-pointer images"
      id="{{ 'vi_camera_profile_alerts_grid_card_image_' + i }}"
      src="{{ data.thumbnailFileUrl }}/?token={{ sessionToken }}"
      alt="IMAGE"
      (error)="eventImage.src = '../../assets/no-preview.svg'"
      (click)="onImageClick(data, i)" />
    <ng-template #errorImage>
      <img
        mat-card-image
        class="cursor-pointer images no-prev"
        id="{{ 'vi_camera_profile_alerts_grid_card_image_error_' + i }}"
        src="../../../assets/no-preview.svg"
        (click)="onImageClick(data, i)" />
    </ng-template>
    <mat-card-content>
      <div class="rig-state" id="{{ 'vi_camera_profile_alerts_grid_card_rig_' + i }}">
        <div>
          <div class="inner-content">
            <div><img src="{{ data?.icon }}" class="card-notations bg-image" id="vi_camera_profile_alerts_grid_card_rig_icon" /></div>
            <div class="rig-info">
              <span class="label" *ngIf="data.label !== ''">{{ data?.label }}: </span>
              <span class="value" *ngIf="data.rigState" matTooltip="{{ data.rigState }}">{{ data.rigState }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="inner-content">
            <div>
              <mat-icon svgIcon="chat" class="bg-image chat-image" id="vi_camera_profile_alerts_grid_card_rig_icon"></mat-icon>
            </div>
            <div class="rig-info">
              <span class="value user-comments" *ngIf="data.lastComment" matTooltip="{{ data.lastComment }}">{{ data.lastComment }}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="pagination-wrapper">
  <mat-paginator
    id="vi_camera_profile_alerts_grid_card_pagination"
    #paginator
    [length]="totalNoRecords"
    showFirstLastButtons
    [pageSizeOptions]="[10, 20, 30, 50, 100]"
    (page)="pageEvent($event)"
    class="camera-profile-grid-pagination">
  </mat-paginator>
</div>
<mat-drawer #drawer class="sidenav" fixedInViewport="true" position="end" [opened]="isOpen" disableClose>
  <app-event-popover
    *ngIf="popOverCameraDetails"
    [eventIndex]="selectedPopOverIndex"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [cameraDetails]="popOverCameraDetails"
    (nextPrevEvent)="onEventClick($event)"
    (updateParentData)="updateParentData($event)"
    (closeSideInfo)="drawer.close(); closeCameraPopOver()"></app-event-popover>
</mat-drawer>
<ng-template #loader>
  <div class="spinner">
    <app-spinner></app-spinner>
  </div>
</ng-template>
