export const environment = {
  production: false,
  apiBaseUrl: 'https://evd-va-acs.agorava.cloud.slb-ds.com',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl: 'https://p4d.csi.cloud.slb-ds.com/v2',
  identityProviderClientKey: 'e7d6337a9f0e7976367247f5cd6dd549',
  identityProviderClientSecret: '275838985ba646cfad15d15f9ce3b4e078d5bc7a24413',
  identityProviderRedirectUrl: 'https://evd-va-acs.agorava.cloud.slb-ds.com/login/logincallback',
  authType: '',
};
